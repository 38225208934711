.card-container {
  perspective: 1000px;
}

.card {
  width: 350px;
  /* height: 200px; */
  position: relative;
  transform-style: preserve-3d;
  transition: transform 0.3s;
  -webkit-transform-style: preserve-3d; 
  -webkit-transition: transform 0.3s;
}

.card.flipped {
  transform: rotateX(180deg);
  
  /* Ensure compatibility */
  -webkit-transform: rotateX(180deg);
}

.card-side {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden; /* Hide the back side when not visible */
  
  /* Ensure compatibility */
  -webkit-backface-visibility: hidden;
}

.card-front, .card-back {
  display: flex;
  /* align-items: center; */
  justify-content: center;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.card-back {
  transform: rotateX(180deg);
  
  /* Ensure compatibility */
  -webkit-transform: rotateX(180deg);
}

/* Media query for mobile devices */
@media (max-width: 600px) {
  .card {
    width: 200px;
  }
}
