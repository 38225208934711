@import url(https://db.onlinewebfonts.com/c/600a6fa6fece9970574dd8a6c00b5cfb?family=Cako+Regular);
@import url("https://fonts.cdnfonts.com/css/aileron");

* {
  font-family: "Aileron";
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.MuiAvatar-root {
  background-color: #ffffff !important;
  border: 1px solid rgba(112, 112, 112, 0.5) !important;
}


* {
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #949494 #f0f0f0; /* Thumb and track colors */
}

*::-webkit-scrollbar {
  width: 1px; /* Set a small width for the scrollbar */
  height: 1px; /* Set a small height for the scrollbar (horizontal scroll) */
}

*::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #949494; /* Track background color */
}

*::-webkit-scrollbar-thumb {
  background: red; 
  border-radius: 4px;
}


.ant-tabs-ink-bar {
  border-bottom: 3px solid rgba(188, 23, 29, 1);
}

.ant-select .ant-select-arrow {
color: rgba(188, 23, 29, 1);
}

.ant-select .ant-select-selector {
  border-radius:20px;
}


.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 10px;
  background-color: #fb6565;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 50%;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: background 0.3s ease-in-out, transform 0.2s;
}

.scroll-to-top:hover {
  background-color: #e60000;
  transform: scale(1.1);
}

