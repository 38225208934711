.labelStyle {
  margin-bottom: 5px;
  color: rgba(112, 112, 112, 1);
  font-family: 'Aileron', sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 31.21px;
}

.inputStyle {
  border-radius: 25px;
  padding-left: 10px;
  height: 38px;
  border: 1px solid rgba(112, 112, 112, 0.5);
}

.inputContainerStyle {
  margin-bottom: 15px;
}

.buttonStyle {
  border-radius: 20px;
  color: white; 
  width: 50%; 
  background-color: rgba(188, 23, 29, 1);
  text-transform: none;
}

.headerStyle  {
  font-family: Cako regular;
  font-size: 22px;
  font-weight: 400;
  line-height: 31.21px;
  text-align: center;
  color: rgba(63, 63, 63, 1)
};

.centerlizing {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px dashed; /* Moved border style here */
  text-align: center; /* Added to center text */
  padding: 20px; /* Optional padding */
}


.picnotePicture {
font-family: Aileron;
font-size: 14px;
font-weight: 400;
line-height: 20px;
text-align: center;
color: rgba(112, 112, 112, 1);

}

.picnotePictureHeader {
  font-family: Aileron;
font-size: 16px;
font-weight: 600;
line-height: 19.2px;
text-align: center;
padding: 10px 0px;

}

/* .dashed {
  width: 460px;
height: 460px;
top: 302px;
left: 440px;
gap: 0px;
border-radius: 15px 0px 0px 0px;
border: 1px 0px 0px 0px;
opacity: 0px;

} */

.inputTextStyle {
  font-family: Aileron;
font-size: 16px;
font-weight: 600;
line-height: 19.2px;
text-align: left;


}

.myEdTools {
font-family: Aileron;
font-size: 14px;
font-weight: 600;
line-height: 21.6px;
text-align: center;
color: rgba(63, 63, 63, 1);

}


.inputContainer {
  position: relative;
  display: flex;
  align-items: center; /* Center image and input vertically */
}

.inputImage {
  width: 84px; /* Set a width for the image */
  height: 50px; /* Set a height for the image */
  position: absolute; /* Position it absolutely */
  right: 10px; /* Distance from the right edge of the input */
  top: 50%; /* Center it vertically */
  transform: translateY(-50%); /* Adjust to center */
  border: 1px solid gainsboro;
  border-radius: 5px;
}

.inputTypeTextStyle {
 
    font-family: Aileron;
  font-size: 12px;
  font-weight: 600;
  line-height: 19.2px;
  text-align: left;
  color: rgba(63, 63, 63, 1)
  
  }

  .submitButton {
    font-size: 14px;             
    line-height: 21.6px;         
    border-radius: 20px;         
    color: white;               
    width: 54%;                  
    font-weight: 400;            
    background-color: rgba(188, 23, 29, 1); 
    padding: 6px;               
    border: none;               
    cursor: pointer;            
    text-transform: none;
}
