.error {
  color: red;
}

.buttonColor {
  color: #fff;
  background-color: rgba(188, 23, 29, 1);
}

.TextStyle {
  border-radius: 10px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #f8f8f8;
}

.header-left a {
  margin-right: 120px;
  text-decoration: none;
  color: #333;

}

.header-center {
  flex-grow: 1;
  text-align: center;
}

.logo {
  max-height: 50px;
}


.header-right button {
  margin-left: 10px;
  padding: 5px 10px;
  border: none;
  cursor: pointer;
}

.login-btn {
  background-color: #007bff;
  color: white;
}

/* .signup-btn {
  background-color: rgba(188, 23, 29, 1);;
  color: white;
} */

.signup-btn {
  background-color: #007bff;
  border: none;
  padding: 10px 20px;
  display: flex;
  align-items: center;
}


/* Style input fields text */
.inputTextStyling {
      font-family: Aileron;
    font-size: 16px;
    font-weight: 600;
    line-height: 19.2px;
    text-align: left;
    color: rgba(63, 63, 63, 1);

}

